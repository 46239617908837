import { ArrowsReload01Custom } from './custom/arrows-reload-01-custom';
import { Building01Custom } from './custom/building-01-custom';
import { CircleCheckCustom } from './custom/circle-check-custom';
import { FlagCustom } from './custom/flag-custom';
import { GolfCart } from './custom/golf-cart';
import { GolfCartCustom } from './custom/golf-cart-custom';
import { LaptopCustom } from './custom/laptop-custom';
import { Logo } from './custom/logo';
import { LogoSquare } from './custom/logo-square';
import { MapCustom } from './custom/map-custom';
import { PlayButton } from './custom/play-button';
import { Prive } from './custom/prive';
import { Share } from './custom/share';
import { StarFilled } from './custom/star-filled';
import { TagCustom } from './custom/tag-custom';
import { UserCheckCustom } from './custom/user-check-custom';
import { UsersCustom } from './custom/users-custom';
import { icons as generated } from './generated';

export const icons = {
	...generated,
	'arrows-reload-01-custom': ArrowsReload01Custom,
	'building-01-custom': Building01Custom,
	'circle-check-custom': CircleCheckCustom,
	'flag-custom': FlagCustom,
	'golf-cart': GolfCart,
	'golf-cart-custom': GolfCartCustom,
	'laptop-custom': LaptopCustom,
	logo: Logo,
	'logo-square': LogoSquare,
	'map-custom': MapCustom,
	'play-button': PlayButton,
	prive: Prive,
	share: Share,
	'star-filled': StarFilled,
	'tag-custom': TagCustom,
	'user-check-custom': UserCheckCustom,
	'users-custom': UsersCustom,
} as const;
