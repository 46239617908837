import { createContext, createRenderEffect, splitProps, useContext } from 'solid-js';
import { isServer, ssr, useAssets } from 'solid-js/web';
import { twMerge } from '@troon/tailwind-preset/merge';
import { icons } from './icons';
import type { ParentProps } from 'solid-js';
import type { IconProps } from './props';

export type IconName = keyof typeof icons;

export { icons };

function initClientProvider() {
	const availableIcons = new Set<IconName>();
	return {
		addIcon(icon: IconName) {
			if (availableIcons.has(icon)) {
				return;
			}

			availableIcons.add(icon);

			const output = icons[icon]();

			document.getElementById('theiconmap')!.innerHTML += typeof output === 'string' ? output : output.content;
		},
	};
}

function initServerProvider() {
	const icons = new Set<IconName>();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	useAssets(() => ssr(renderIcons(icons)) as any);

	return {
		addIcon(icon: IconName) {
			icons.add(icon);
		},
	};
}

function renderIcons(toRender: Set<IconName>) {
	return `<svg width="0" height="0" class="hidden" id="theiconmap">
${Array.from(toRender)
	.map((name) => {
		const ret = icons[name]();
		return typeof ret === 'string' ? ret : ret.content;
	})
	.join('')}
</svg>`;
}

type CTX = {
	addIcon: (icon: IconName) => void;
};
const IconContext = createContext<CTX>();

export function IconProvider(props: ParentProps) {
	const actions = !isServer ? initClientProvider() : initServerProvider();
	return <IconContext.Provider value={actions}>{props.children}</IconContext.Provider>;
}

export function useHead(icon: IconName) {
	const ctx = useContext(IconContext);
	if (!ctx) {
		return;
	}

	createRenderEffect(() => {
		ctx.addIcon(icon);
	});
}

export function Icon(props: IconProps & { name: keyof typeof icons }) {
	useHead(props.name);
	const [, svgProps] = splitProps(props, ['name']);

	const value = icons[props.name]();

	return (
		<svg
			{...(typeof value === 'string' ? defaultProps : (value.props ?? defaultProps))}
			{...svgProps}
			class={twMerge('inline-flex', svgProps.class)}
			aria-hidden={props.title ? 'false' : 'true'}
			xmlns="http://www.w3.org/2000/svg"
		>
			<use href={`#icon-${props.name}`} />
		</svg>
	);
}

const defaultProps = {
	viewBox: '0 0 24 24',
	width: '1.2em',
	height: '1.2em',
	fill: 'none',
	stroke: 'none',
};
