import { ArrowCircleRight } from './arrow-circle-right';
import { ArrowLeftMd } from './arrow-left-md';
import { ArrowRightMd } from './arrow-right-md';
import { ArrowUpRightMd } from './arrow-up-right-md';
import { ArrowsReload01 } from './arrows-reload-01';
import { BellRing } from './bell-ring';
import { Calendar } from './calendar';
import { CaretDownMd } from './caret-down-md';
import { CaretUpMd } from './caret-up-md';
import { Check } from './check';
import { ChevronLeft } from './chevron-left';
import { ChevronRight } from './chevron-right';
import { CircleCheck } from './circle-check';
import { CircleHelp } from './circle-help';
import { CircleWarning } from './circle-warning';
import { Clock } from './clock';
import { CloseCircle } from './close-circle';
import { CloseMd } from './close-md';
import { Copy } from './copy';
import { CreditCard01 } from './credit-card-01';
import { Devices } from './devices';
import { EditPencil01 } from './edit-pencil-01';
import { ExternalLink } from './external-link';
import { Filter } from './filter';
import { Flag } from './flag';
import { Globe } from './globe';
import { Grid } from './grid';
import { HamburgerMd } from './hamburger-md';
import { House01 } from './house-01';
import { Info } from './info';
import { LogOut } from './log-out';
import { Map } from './map';
import { MapPin } from './map-pin';
import { MailOpen } from './mail-open';
import { MobileButton } from './mobile-button';
import { Navigation } from './navigation';
import { Phone } from './phone';
import { SearchMagnifyingGlass } from './search-magnifying-glass';
import { ShareAndroid } from './share-android';
import { ShareIosExport } from './share-ios-export';
import { SquareWarning } from './square-warning';
import { Star } from './star';
import { Tag } from './tag';
import { TrashFull } from './trash-full';
import { UnfoldMore } from './unfold-more';
import { User02 } from './user-02';
import { UserCardId } from './user-card-id';
import { UserCircle } from './user-circle';
import { UserSquare } from './user-square';
import { Users } from './users';

export const icons = {
	'arrow-circle-right': ArrowCircleRight,
	'arrow-left-md': ArrowLeftMd,
	'arrow-right-md': ArrowRightMd,
	'arrow-up-right-md': ArrowUpRightMd,
	'arrows-reload-01': ArrowsReload01,
	'bell-ring': BellRing,
	calendar: Calendar,
	'caret-down-md': CaretDownMd,
	'caret-up-md': CaretUpMd,
	check: Check,
	'chevron-left': ChevronLeft,
	'chevron-right': ChevronRight,
	'circle-check': CircleCheck,
	'circle-help': CircleHelp,
	'circle-warning': CircleWarning,
	clock: Clock,
	'close-circle': CloseCircle,
	'close-md': CloseMd,
	copy: Copy,
	'credit-card-01': CreditCard01,
	devices: Devices,
	'edit-pencil-01': EditPencil01,
	'external-link': ExternalLink,
	filter: Filter,
	flag: Flag,
	globe: Globe,
	grid: Grid,
	'hamburger-md': HamburgerMd,
	'house-01': House01,
	info: Info,
	'log-out': LogOut,
	map: Map,
	'map-pin': MapPin,
	'mail-open': MailOpen,
	'mobile-button': MobileButton,
	navigation: Navigation,
	phone: Phone,
	'search-magnifying-glass': SearchMagnifyingGlass,
	'share-android': ShareAndroid,
	'share-ios-export': ShareIosExport,
	'square-warning': SquareWarning,
	star: Star,
	tag: Tag,
	'trash-full': TrashFull,
	'unfold-more': UnfoldMore,
	'user-02': User02,
	'user-card-id': UserCardId,
	'user-circle': UserCircle,
	'user-square': UserSquare,
	users: Users,
};
